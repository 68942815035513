import { makeStyles } from '@material-ui/core/styles';
import { desktopMedia, pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => {
  return {
    CollageType2Wrapper: {
      ...theme.shape.componentSpacing,
      marginTop: pxToRem(48),
      [desktopMedia]: {
        marginTop: pxToRem(88),
      },

      '& .MuiTypography-h2': {
        ...theme.mixins.marginLeft(0),
      },

      '& .heading': {
        color: theme.palette.text.sectionHeading,
        [theme.breakpoints.down('md')]: {
          marginBottom: pxToRem(21),
        },
      },
    },
    CollageType2WrapperWithBg: {
      backgroundColor: ({ params }) => params.backgroundColor,
      ...theme.shape.fullBleedingBox,
      ...theme.shape.componentSpacing,
    },
    imgHeight: {
      height: '224px',
      [theme.breakpoints.up('lg')]: {
        height: '80vh',
        maxHeight: '50vw',
      },
      [theme.breakpoints.down('sm')]: {
        height: '163px',
      },
    },
  };
});

export default useStyles;
