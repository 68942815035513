import React, { useEffect, useRef } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';
import { Hidden } from '@material-ui/core';

import isEmpty from 'lodash/isEmpty';
import Title from '../../../components/atoms/Title';
import Grid from '../../../components/molecules/Grid';
import Button from '../../../components/atoms/Button';
import Typography from '../../../components/atoms/Typography';
import Link from '../../../components/atoms/Link';
import ImageTileWithTextOnTop from '../../../components/molecules/ImageTileWithTextOnTop';
import { pushAnaylyticsData } from '../../../../../src/utils/analytics';
import { analyticsData } from '../../../../../src/constants/Analytics/analytics-data';
import clsx from 'clsx';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import TextRotateAnimation from '../../../components/atoms/TextRotateAnimation';

import { createDynamicLabels } from '../../../../utils/analytics';
import useStyles from './style';

import { COMPONENT_NAME } from '../../../containers/common/CollageType2/locators'
import { COMPONENT_NAME as EditorialGridName } from '../../../containers/common/EditorialGrid/locators'

const CategoryCardComponent = ({
  tileData,
  segmentTitle,
  segmentHeading,
  description,
  primaryCTA,
  aspectRatio,
  isPriceCard,
  pageName,
  componentName,
  disableHover,
  pictureClass = '',
}) => {
  const isEditor = isExperienceEditorActive();
  const isEditorialComponent = componentName === EditorialGridName

  const classes = useStyles({ isEditor, isPriceCard });

  const subTitleElem = useRef(null),
    titleElem = useRef(null),
    catCardComp = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    setTimeout(() => {
      const catCardCompAnim = gsap.timeline();
      catCardCompAnim.pause();

      let haveAnimatedOnce = false;
      gsap.timeline({
        scrollTrigger: {
          trigger: catCardComp.current,
          start: 'top bottom',
          end: `+=${(window.androidHeight || window.innerHeight) * 0.2}px bottom`,
          scrub: true,
          once: true,
          onUpdate: self => {
            if (self.progress > 0.15) {
              // catCardCompAnim.play();
              subTitleElem && subTitleElem.current && subTitleElem.current.animPlay();
              titleElem && titleElem.current && titleElem.current.animPlay();
              haveAnimatedOnce = true;
            } else if (haveAnimatedOnce) {
              // catCardCompAnim.reverse();
              subTitleElem && subTitleElem.current && subTitleElem.current.animReverse();
              titleElem && titleElem.current && titleElem.current.animReverse();
            }
          },
        },
      });
    }, 100);
  });

  const renderTiles =
    tileData &&
    tileData.map(({ fields }, index) => {
      const hasLink = fields?.imageTextLink?.value.href;
      const Component = disableHover || !hasLink ? 'div' : Link;
      return (
        <Grid item xs={12} lg={4} key={index}>
          <Component
            field={!disableHover && fields && fields.imageTextLink}
            className={clsx(
              classes.titleTextLinkWrap,
              isPriceCard ? 'price-card' : '',
            )}
          >
            <ImageTileWithTextOnTop
              pictureClass={pictureClass}
              title={fields && fields.title}
              segmentHeading={segmentHeading}
              subTitle={fields && fields.subTitle}
              titleClass={isPriceCard ? 'price-card-title' : ''}
              titleVariant={isEditorialComponent ? 'h5Bold' : isPriceCard ? 'cardTitle1' : 'h4'}
              background={fields.backgroundColor}
              strip={isPriceCard ? fields.stripColor : {}}
              isPriceCard={isPriceCard}
              media={
                {
                  image: fields && fields.image,
                  mobileImage: fields && fields.mobileImage,
                  aspectRatio,
                }
              }
              pageName={pageName}
              componentName={componentName}
              disableHover={disableHover || !hasLink}
            />
          </Component>
        </Grid>
      );
    });
  const addAnalyticsInfo = () => {
    const obj = {
      ...analyticsData.pageBodyInteraction.arrowClick,
      ...{
        label: createDynamicLabels([pageName, componentName, `${segmentHeading && segmentHeading.value} - ${get(
          primaryCTA,
          'value.text'
        )}`]),
      },
    };
    pushAnaylyticsData(obj);
  };

  return (
    <Grid className={classes.root} ref={catCardComp}>
      <Grid
        container
        spacing={0}
        classes={{ root: `${classes.root}` }}
        alignItems="center"
      >
        {segmentTitle && segmentTitle.value && (
          <Grid item xs={12} className='segment-title'>
            <TextRotateAnimation
              condition={true}
              animateSettings={{
                willAnimate: true,
                selfTrigger: true,
                duration: '250ms',
              }}
              ref={subTitleElem}
            >
              <Title text={segmentTitle} />
            </TextRotateAnimation>
          </Grid>
        )}
        {segmentHeading && segmentHeading.value && (
          <Grid item xs={12}>
            <Typography
              variant={isPriceCard ? 'h4' : 'h2'}
              component="h2"
              classNameChild={clsx(classes.heading, 'heading')}
              wordBreak
              ref={titleElem}
              animateSettings={{
                willAnimate: true,
                selfTrigger: true,
              }}
            >
              <Text field={segmentHeading} />
            </Typography>
          </Grid>
        )}
        {description && description.value && (
          <Grid item xs={12}>
            <Typography
              variant="body1"
              component="p"
              classes={{ root: 'listingDescription' }}
            >
              <Text field={description} />
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={0}>
            {primaryCTA && primaryCTA.value.text && primaryCTA.value.href && (
              <Hidden mdDown implementation="css">
                <Grid item xs={12} className={classes.primaryCTAWrap}>
                  <Button
                    onClick={() => addAnalyticsInfo()}
                    component="div"
                    buttonType="primary"
                    field={primaryCTA}
                    srText={get(primaryCTA, 'value.text', '')}
                    hasBorder={false}
                  />
                </Grid>
              </Hidden>
            )}
            {tileData && (
              <Grid item xs={12} className={componentName === COMPONENT_NAME ? classes.mtop28 : ''}>
                <Grid container spacing={1}>
                  {renderTiles}
                </Grid>
              </Grid>
            )}
            {primaryCTA && primaryCTA.value.text && primaryCTA.value.href && (
              <Hidden lgUp implementation="css">
                <Grid item xs={12} className={classes.mtop25}>
                  <Button
                    onClick={() => addAnalyticsInfo()}
                    component="div"
                    buttonType="primary"
                    field={primaryCTA}
                    hasBorder={false}
                    srText={get(primaryCTA, 'value.text', '')}
                  />
                </Grid>
              </Hidden>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CategoryCardComponent.propTypes = {
  tileData: PropTypes.array,
  segmentTitle: PropTypes.shape({
    value: PropTypes.string,
  }),
  segmentHeading: PropTypes.shape({
    value: PropTypes.string,
  }),
  primaryCTA: PropTypes.oneOfType([PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
    anchor: PropTypes.string,
    linktype: PropTypes.string,
    class: PropTypes.string,
    title: PropTypes.string,
    querystring: PropTypes.string,
  }), PropTypes.string]),
  aspectRatio: PropTypes.shape({
    mobile: PropTypes.string,
    desktop: PropTypes.string,
  }),
};

export default withSitecoreContext()(withNamespaces()(CategoryCardComponent));
