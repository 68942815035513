import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return {
    overlay: {
      overflow: 'hidden',
      position: 'relative',
      transition: 'all 1s ease',
      height: '100%',
      backgroundColor: props => props.bgColor,
      borderBottom: props => `4px solid ${props.stripColor}`,
      '& .img-to-animate-wrapper': {
        transition: 'all 1s ease',
      },
      '&:hover': {
        boxShadow: `0px 2px 20px 0px ${theme.mixins.Black(800)}`,
        '& :not(.img-to-animate-wrapper) picture, & .img-to-animate-wrapper': {
          transform: 'scale3d(1.1, 1.1, 1.1)',
          '&::before': {
            background: theme.mixins.Black(800),
          },
        },
      },
      '&.disableHover': {
        boxShadow: 'none',
      },
    },
    cardPicture: {
      transition: 'all 1s ease',
      display: 'flex',
      height: '100%',
    },
    cardPictureWithLink: {
      '&::before': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        content: "''",
        display: 'inline',
        transition: 'all 1s ease',
        background: theme.mixins.Black(400),
        top: '0',
        left: '0',
        zIndex: '10',
      },
    },
    cardContentWrapper: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      textAlign: 'center',
      zIndex: '11',
      padding: `0 ${theme.spacing(4)}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.up('lg')]: {
        padding: `0 ${theme.spacing(7)}`,
      },
      [theme.breakpoints.down('md')]: {
        padding: `0`,
      },
    },
  };
});

export default useStyles;
