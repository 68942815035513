import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexGrow: '1',
      '& .btnWithBorder': {
        [theme.breakpoints.down('md')]: {
          paddingTop: theme.spacing(3),
        },
      },
    },
    heading: {
      ...theme.palette.common.ellipsis(),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('lg')]: {
        width: pxToRem(910),
        ...theme.palette.common.ellipsis(1),
        marginBottom: ({ isPriceCard }) =>
          isPriceCard ? theme.spacing(5) : 8,
      },
      [theme.breakpoints.up('xl')]: {
        width: '100%',
      },
    },
    titleTextLinkWrap: {
      color: `${theme.palette.secondary.main}`,
      height: props => !props.isEditor && '100%',
      display: 'block',

      '& h3': {
        ...theme.palette.common.ellipsis(2),
      }
    },
    primaryCTAWrap: {
      paddingBottom: 0,
    },
    mtop28: {
      marginTop: pxToRem(24),
      [theme.breakpoints.up('lg')]: {
        marginTop: '5.5rem'
      }
    },
    mtop25: {
      marginTop: pxToRem(25)
    }
  };
});
export default useStyles;
