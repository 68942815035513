import React from 'react';
import get from 'lodash/get';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import CategoryCardComponent from '../../../components/organism/CategoryCardComponent/';
import Box from '../../../components/molecules/Box';

import useStyles from './style';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { COMPONENT_NAME } from './locators'
import { getComponentName, getPageName } from '../../../utils/getPageComponentInfo';
import checkEmptyObject from '../../../utils/checkEmptyObject';

const aspectRatio = { mobile: '2x1', desktop: '4x5' };
const CollageType2 = props => {
  const isFieldsEmpty = checkEmptyObject(props.fields);
  const { collageImageWithText, segmentTitle, collageTypeTitle, iconLink } =
    props && props.fields;
  const classes = useStyles(props);
  const pageName = getPageName(props);
  const componentName = getComponentName(props);
  return (
    isFieldsEmpty ? <></> :
      <Box
        className={
          get(props, 'params.backgroundColor', '')
            ? classes.CollageType2WrapperWithBg
            : classes.CollageType2Wrapper
        }
        data-locator={COMPONENT_NAME}
      >
        <CategoryCardComponent
          tileData={collageImageWithText}
          segmentTitle={segmentTitle}
          segmentHeading={collageTypeTitle}
          primaryCTA={iconLink}
          aspectRatio={aspectRatio}
          componentName={componentName}
          pageName={pageName}
          pictureClass={classes.imgHeight}
        />
      </Box>
  );
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(CollageType2))
);
